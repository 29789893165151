<template>
  <div>
    <hr>
    <b-row>
      <b-col cols="12">
        <h6 class="text-primary">
          Trabajos realizados
        </h6>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="getTrabajos()"
          style-class="vgt-table condensed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'tipo'">{{ getDetalleTipo( props.row) }}</span>
            <span v-else-if="props.column.field == 'detalle'">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-1
                variant="gradient-primary"
                @click="mostrarDetalle(props)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <div>
      <div>
        <b-modal
          v-model="verModalDetallePunto"
          ok-only
          ok-variant="primary"
          ok-title="Aceptar"
          modal-class="modal-primary"
          centered
          title="Punto de Iluminación"
        >
          <detail-punto
            :id="punto.id"
            :obj-localidad="localidad"
            :punto="JSON.parse(JSON.stringify(punto))"
          />
        </b-modal>
      </div>
    </div>
    <div>
      <b-modal
        v-model="verModalSeguimiento"
        ok-only
        ok-variant="primary"
        ok-title="Aceptar"
        modal-class="modal-primary"
        centered
        :title="`TRABAJOS REALIZADOS DE ${getDetalleTipo(seguimientoSeleccionado)}`"
      >
        <TableTrabajosDetalle
          :title="getDetalleTipo(seguimientoSeleccionado)"
          :trabajos="seguimientoSeleccionado.detalles? seguimientoSeleccionado.detalles.trabajos: []"
          :migracion="migracion"
          @actualizar="cerrarModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BCol, BFormGroup, BFormInput, BFormTextarea, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import DetailPunto from '@/components/PuntosIluminacion/DetailPunto.vue'
import Ripple from 'vue-ripple-directive'
import { tiposTrabajos } from '@/utils/migracionLuminarias'
import TableTrabajosDetalle from '@/components/levantamiento/TableTrabajosDetalle.vue'

export default {
  components: {
    TableTrabajosDetalle,
    DetailPunto,
    BCol,
    BRow,
    BButton,
    BFormInput,
    BFormGroup,
    VueGoodTable,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    migracion: {
      type: Object,
      required: true,
    },
    localidad: {
      type: Object,
      required: false,
      default: () => {},
    },
    punto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      verModalDetallePunto: false,
      verModalSeguimiento: false,
      seguimientoSeleccionado: {},
      columns: [
        {
          label: 'Trabajo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  created() {
    // console.log('Datos migracion', this.migracion)
    // console.log('Datos punto', this.punto)
  },
  methods: {
    verDetallePoste() {
      this.verModalDetallePunto = true
    },
    getNombreTrabajosRealizarPoste() {
      return this.migracion.levantamientoPunto.poste.trabajos.map(trabajo => trabajo.nombre).join(', ')
    },
    getTrabajos() {
      if (!this.migracion || !this.migracion.trabajos) {
        return []
      }
      return Object.keys(this.migracion.trabajos).map(tipo => ({
        tipo,
        detalles: this.migracion.trabajos[tipo],
      }))
    },
    mostrarDetalle(params) {
      this.seguimientoSeleccionado = params.row
      // console.log(this.seguimientoSeleccionado)
      this.verModalSeguimiento = true
    },
    getDetalleTipo(row) {
      return tiposTrabajos[row.tipo]
    },
    cerrarModal() {
      this.verModalSeguimiento = false
      this.$emit('actualizar')
    },
  },
}

</script>
