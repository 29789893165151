<template>
  <b-overlay :show="loading">
    <b-tabs
      v-if="punto"
      content-class="pt-1"
      fill
    >
      <b-tab
        title="LEVANTAMIENTO DE DATOS"
        :active="!mostrarTabSeguimiento()"
      >
        <div v-if="!loading">
          <b-row :class="{ 'inputs-desabilitados': isMigrada() }">
            <b-col cols="12">
              <validation-observer ref="formulario">
                <b-form>
                  <b-card>
                    <b-row>
                      <b-col cols="8">
                        <h2>Punto No. {{ punto.numeroPoste }}</h2>
                      </b-col>
                      <b-col
                        v-if="punto.id"
                        cols="4"
                      >
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="relief-primary"
                          block
                          style="margin-top: 1.75rem"
                          @click="verDetallePoste"
                        >
                          Detalle del Punto
                        </b-button>
                      </b-col>
                    </b-row>
                    <br>
                    <!------------------------------------- ENCABEZADO ---------------------->
                    <b-row>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Número Poste:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Número de Poste"
                            rules="required"
                          >
                            <b-form-input
                              v-model="punto.detalleInicial.poste.numeroPoste"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Potencia Actual
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Potencia Instalada"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.potencia"
                              :state="errors.length > 0 ? false : null"
                              :options="potencias"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Correlativo de Lámpara
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Serie de Lámpara"
                            rules="required"
                          >
                            <b-form-input
                              v-model="punto.correlativo"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!------------------------------------- DATOS PUNTO NUEVO ---------------------->
                    <b-row v-if="!punto.id">
                      <b-col cols="12">
                        <hr>
                        <h4 align="center">
                          Datos Generales Poste
                        </h4>
                      </b-col>
                      <b-col cols="3">
                        <h5 class="text-primary">
                          Calle / Avenida
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Calle / Avenida"
                            rules="required"
                          >
                            <b-form-input
                              v-model="punto.calleAvenida"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Numeral
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Numeral"
                            rules="required"
                          >
                            <b-form-input
                              v-model="punto.numeral"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="5">
                        <h5 class="text-primary">
                          Localidad:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Localidad"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.localidad"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="localidades"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!------------------------------------- LUMINARIA INSTALADA ---------------------->
                    <b-row>
                      <b-col cols="12">
                        <hr>
                        <h4 align="center">
                          Luminaria Actual
                        </h4>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Dispositivo:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Dispositivo"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.dipositivoLampara"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="dispositivos"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Clase:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Clase"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.modeloLampara"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="clasesLampara"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Tipo (Tecnología):
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.tecnologiaLampara"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="tecnologias"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!------------------------------------- POSTE ---------------------->
                    <b-row>
                      <b-col cols="12">
                        <hr>
                        <h4 align="center">
                          Poste
                        </h4>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Tipo:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo de Poste"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.poste.tipoPoste"
                              :state="errors.length > 0 ? false : null"
                              label="nombre"
                              :options="tiposPoste"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Propiedad:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Propiedad"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.poste.propiedad"
                              :state="errors.length > 0 ? false : null"
                              label="nombre"
                              :options="propiedades"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Estado:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Estado Poste"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.poste.estado"
                              :state="errors.length > 0 ? false : null"
                              label="nombre"
                              :options="estados"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <h5 class="text-primary">
                          Tamaño:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tamaño"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.poste.tamano"
                              :state="errors.length > 0 ? false : null"
                              label="nombre"
                              :options="tamañosPoste"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="9">
                        <h5 class="text-primary">
                          Trabajos a Realizar:
                        </h5>
                        <v-select
                          v-model="posteTrabajos"
                          label="nombre"
                          :options="trabajosPoste"
                          multiple
                        />
                        <br>
                      </b-col>
                      <b-col
                        v-if="showPosteInstalar"
                        cols="12"
                      >
                        <h5 class="text-primary">
                          Poste a Instalar:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Poste a Instalar"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.poste.posteInstalar"
                              :state="errors.length > 0 ? false : null"
                              label="nombre"
                              :options="tipoPostesInstalacion"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!------------------------------------- BRAZO ---------------------->
                    <b-row>
                      <b-col cols="12">
                        <hr>
                        <h4 align="center">
                          Brazo
                        </h4>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Tipo:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo de Brazo"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.brazo.tipo"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="tiposBrazos"
                              label="nombre"
                              style="font-size: 0.8rem"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Herraje Instalado:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Herraje Instalado"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.brazo.tipoHerraje"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="tiposHerrajesInstalados"
                              label="nombre"
                              style="font-size: 0.8rem"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Estado:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Estado Brazo"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.brazo.estado"
                              :state="errors.length > 0 ? false : null"
                              label="nombre"
                              :options="estados"
                              style="font-size: 0.8rem"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <h5 class="text-primary">
                          Tamaño:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tamaño"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.brazo.tamano"
                              :state="errors.length > 0 ? false : null"
                              label="nombre"
                              :options="tamañosBrazo"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="9">
                        <h5 class="text-primary">
                          Trabajos a Realizar:
                        </h5>
                        <v-select
                          v-model="brazoTrabajos"
                          label="nombre"
                          :options="trabajosBrazo"
                          multiple
                        />
                        <br>
                      </b-col>
                      <b-col
                        v-if="showBrazoInstalar"
                        cols="6"
                      >
                        <h5 class="text-primary">
                          Tipo de Brazo a Instalar:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo de Brazo"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.brazo.brazoInstalar"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="tamanoBrazos"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="showHerrajeInstalar"
                        cols="6"
                      >
                        <h5 class="text-primary">
                          Tipo de Herraje a Instalar:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo de Herraje"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.brazo.herrajeInstalar"
                              :state="errors.length > 0 ? false : null"
                              :options="herrajes"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!------------------------------------- CONEXIÓN ---------------------->
                    <b-row>
                      <b-col cols="12">
                        <hr>
                        <h4 align="center">
                          Tipo de Conexión
                        </h4>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Conectada a:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo de Conexión"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.conexion.tipo"
                              :state="errors.length > 0 ? false : null"
                              :options="tipoConexiones"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <h5 class="text-primary">
                          Trabajos a Realizar:
                        </h5>
                        <v-select
                          v-model="punto.detalleInicial.conexion.trabajos"
                          label="nombre"
                          :options="trabajosConexion"
                          multiple
                          @input="cambioTrabajoConexion"
                        />
                        <br>
                      </b-col>
                      <b-col
                        v-if="showMetrosTriplex"
                        cols="2"
                      >
                        <h5 class="text-primary">
                          Metros
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Metros Triplex"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                punto.detalleInicial.conexion.cantidadTriplex
                              "
                              type="number"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!------------------------------------- LÁMPARA ---------------------->
                    <b-row>
                      <b-col cols="12">
                        <hr>
                        <h4 align="center">
                          Luminaria a Instalar
                        </h4>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Dispositivo:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Dispositivo"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.lampara.dispositivo"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="dispositivos"
                              label="nombre"
                              style="font-size: 0.82rem"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Clase:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Clase"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.lampara.clase"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="clasesLampara"
                              label="nombre"
                              style="font-size: 0.82rem"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <h5 class="text-primary">
                          Tipo (Tecnología):
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.lampara.tipo"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="tecnologias"
                              label="nombre"
                              style="font-size: 0.85rem"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="9">
                        <h5 class="text-primary">
                          Potencia:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Potencia a Instalar"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.lampara.lamparaInstalar"
                              :state="errors.length > 0 ? false : null"
                              :options="tipoLamparas"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <h5 class="text-primary">
                          Ángulo:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Ángulo"
                            rules="required"
                          >
                            <v-select
                              v-model="
                                punto.detalleInicial.lampara.anguloInstalacion
                              "
                              style="font-size: 12px"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="angulosInstalacion"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <h5 class="text-primary">
                          Trabajos a Realizar:
                        </h5>
                        <v-select
                          v-model="punto.detalleInicial.lampara.trabajos"
                          label="nombre"
                          :options="trabajosLampara"
                          multiple
                        />
                        <br>
                      </b-col>
                    </b-row>
                    <!------------------------------------- FOTO Y COMENTARIO FINAL ---------------------->
                    <b-row>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                      <b-col cols="2">
                        <h5 class="text-primary">
                          Tipo de Trabajo:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo de Trabajo"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.tipoTrabajo"
                              style="font-size: 12px"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="tiposTrabajo"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="2">
                        <h5 class="text-primary">
                          Tipo de Instalación:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo de Instalación"
                            rules="required"
                          >
                            <v-select
                              v-model="punto.detalleInicial.tipoInstalacion"
                              style="font-size: 12px"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false : null"
                              :options="tiposInstalacion"
                              label="nombre"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col :cols="showMetrosPoda ? 6 : 8">
                        <h5 class="text-primary">
                          Otros Trabajos:
                        </h5>
                        <v-select
                          v-model="trabajosOtros"
                          label="nombre"
                          :options="otrosTrabajos"
                          multiple
                        />
                        <br>
                      </b-col>
                      <b-col
                        v-if="showMetrosPoda"
                        cols="2"
                      >
                        <h5 class="text-primary">
                          Metros
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Metros Poda"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="punto.detalleInicial.metrosPoda"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <h5
                          align="center"
                          class="text-primary"
                        >
                          Comentarios Generales:
                        </h5>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Comentarios Generales"
                            rules="required"
                          >
                            <b-form-textarea
                              id="comentariosBrazoPoste"
                              v-model="punto.detalleInicial.comentariosGenerales"
                              rows="3"
                              no-resize
                              required
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <h5
                          align="center"
                          class="text-primary"
                        >
                          Fotografía Panorámica
                        </h5>
                        <br>
                        <center>
                          <div v-if="migracionLuminiaria && migracionLuminiaria.levantamientoPunto && migracionLuminiaria.levantamientoPunto.imagenPanoramica">
                            <ImagenShowReemplazo
                              :url-imagen="migracionLuminiaria.levantamientoPunto.imagenPanoramica.url"
                              @nueva-foto="nuevaImagen"
                            />
                          </div>
                          <div v-else>
                            <imagen
                              :key="'panoramica'"
                              :leyenda="'imagen-panoramica'"
                              @cargar-file="setImagenPanoramica"
                            />
                          </div>
                        </center>
                      </b-col>
                    </b-row>
                    <!------------------------------------- BOTON GUARDAR ---------------------->
                    <b-row v-if="mostrarBotonGuardar">
                      <b-col cols="12">
                        <hr>
                        <br>
                        <center>
                          <b-button
                            style="margin-top: -0.25rem !important; height: 50px"
                            variant="success"
                            block
                            @click="guardarInspeccion"
                          >
                            <feather-icon
                              icon="SaveIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">Guardar</span>
                          </b-button>
                        </center>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
          <div>
            <b-modal
              v-model="dialogDetallePunto"
              ok-only
              ok-variant="primary"
              ok-title="Aceptar"
              modal-class="modal-primary"
              centered
              title="Punto de Iluminación"
            >
              <b-card class="card-poste">
                <detail-punto
                  v-if="puntoDetalle"
                  :id="puntoDetalle.id"
                  :obj-localidad="localidad"
                  :punto="JSON.parse(JSON.stringify(punto))"
                />
              </b-card>
            </b-modal>
          </div>
        </div>
      </b-tab>
      <b-tab
        v-if="migracionLuminiaria && migracionLuminiaria.estado !== 'INGRESADA'"
        title="DETALLE DE SEGUIMIENTO"
        :active="mostrarTabSeguimiento()"
      >
        <b-card>
          <b-row>
            <b-col cols="4">
              <span class="text-primary">Número Poste</span>
              <b-form-input
                :value="migracionLuminiaria.numeroPoste"
                disabled
              />
            </b-col>
            <b-col cols="6">
              <span class="text-primary">Técnico Encargado</span>
              <b-form-input
                :value="migracionLuminiaria && migracionLuminiaria.usuario && migracionLuminiaria.usuario.nombre"
                disabled
              />
            </b-col>
            <b-col cols="2">
              <span class="text-primary">
                Estado
              </span>
              <b-form-input
                :value="migracionLuminiaria.estado"
                disabled
              />
            </b-col>
            <b-col
              v-if="punto && punto.obj_localidad"
              cols="3"
            >
              <span class="text-primary">
                Municipio
              </span>
              <b-form-input
                :value="punto && punto.obj_localidad && punto.obj_localidad.municipio.nombre"
                disabled
              />
            </b-col>
            <b-col
              v-if="punto && punto.obj_localidad"
              cols="7"
            >
              <span class="text-primary">Localidad/Población</span>
              <label />
              <b-form-input
                :value="punto && punto.obj_localidad && punto.obj_localidad.nombre"
                disabled
              />
            </b-col>
            <b-col
              v-if="punto && punto.obj_localidad"
              cols="2"
            >
              <span class="text-primary">Zona</span>
              <b-form-input
                :value="punto && punto.obj_localidad && punto.obj_localidad.zona"
                disabled
              />
            </b-col>
            <b-col cols="6">
              <span class="text-primary">
                Comentarios
              </span>
              <b-form-textarea
                :value="punto && punto.observaciones"
                no-resize
                disabled
              />
            </b-col>
            <b-col cols="6">
              <span class="text-primary">
                Ubicación
              </span>
              <b-button
                v-if="migracionLuminiaria.latitud !== '' && migracionLuminiaria.longitud !==''"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                block
                @click="irRuta(punto)"
              >
                <feather-icon icon="MapPinIcon" />
                <br>
                <br>
                Ver Ubicación en Google Maps
              </b-button>
            </b-col>
          </b-row>
          <hr v-if="hasLuminariaData">
          <b-row v-if="hasLuminariaData">
            <b-col cols="12">
              <h5 class="text-primary">
                Información de Luminaria Instalada
              </h5>
            </b-col>
            <b-col cols="3">
              <span class="text-primary">Serie</span>
              <b-form-input
                :value="migracionLuminiaria.seguimiento[0].datosLuminaria.serie"
                disabled
              />
            </b-col>
            <b-col cols="3">
              <span class="text-primary">Tipo</span>
              <b-form-input
                :value="migracionLuminiaria.seguimiento[0].datosLuminaria.tipo"
                disabled
              />
            </b-col>
            <b-col cols="4">
              <span class="text-primary">Tipo Lámpara</span>
              <b-form-input
                :value="migracionLuminiaria.seguimiento[0].datosLuminaria.tipoLampara.nombre"
                disabled
              />
            </b-col>
            <b-col cols="2">
              <span class="text-primary">Potencia</span>
              <b-form-input
                :value="migracionLuminiaria.seguimiento[0].datosLuminaria.potencia.nombre"
                disabled
              />
            </b-col>
          </b-row>
          <hr v-if="hasNemaData">
          <b-row v-if="hasNemaData">
            <b-col cols="12">
              <h5 class="text-primary">
                Información de Nema Instalada
              </h5>
            </b-col>
            <b-col cols="3">
              <span class="text-primary">Serie</span>
              <b-form-input
                :value="migracionLuminiaria.seguimiento[0].datosNema.serie"
                disabled
              />
            </b-col>
            <b-col cols="5">
              <span class="text-primary">Tipo</span>
              <b-form-input
                :value="migracionLuminiaria.seguimiento[0].datosNema.tipo.nombre"
                disabled
              />
            </b-col>
            <b-col cols="4">
              <span class="text-primary">Potencia</span>
              <b-form-input
                :value="migracionLuminiaria.seguimiento[0].datosNema.potencia.nombre"
                disabled
              />
            </b-col>
          </b-row>

          <b-row v-if="migracionLuminiaria.estado === 'MIGRADA'">
            <b-col
              v-if="rolesAdmin.includes(usuario.role)"
              cols="12"
            >
              <hr>
              <b-button
                block
                variant="success"
                @click="abrirModalVerificacion"
              >
                Verificación
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="12">
              <h6 style="color: #006394;">
                Seguimiento Ticket
              </h6>
            </b-col>
            <b-col cols="12">
              <vue-good-table
                :rows="migracionLuminiaria.seguimiento"
                :columns="camposTablaSeguimiento"
                style-class="vgt-table condensed"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'detalle'">
                    <ImagenShowReemplazo
                      :posicion-imagen="props.index"
                      :url-imagen="props.row.url"
                      :clave="'seguimiento'"
                      :tipo-obj="'Array'"
                      :ticket="migracionLuminiaria"
                      :origen="origenes.migraciones"
                    />
                  </span>
                  <span v-else-if="props.column.field === 'fechaHora'">
                    {{ formatFecha(props.row.fechaHora) }}
                  </span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <detalle-levantamiento-inicial-migraciones
                :migracion="migracionLuminiaria"
                :punto="punto"
                :localidad="localidad"
                @actualizar="cerrarModal"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>
    <div>
      <b-modal
        id="modal-verificaciones"
        v-model="modalVerificacion"
        title="Verificación de Levantamiento"
        modal-class="modal-primary"
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
      >
        <modal-verificacion-levantamiento
          :migracion="migracionLuminiaria"
          @notificar="notificarCambio"
        />
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  VBModal,
  BCard,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay, BTabs, BTab,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import 'vue-good-table/dist/vue-good-table.css'
import DetailPunto from '@/components/PuntosIluminacion/DetailPunto.vue'
import { required } from '@validations'
import { getListadoDetalle } from '@/utils/detallePunto'
import {
  mensajeConfirmar,
  mensajeError,
  mensajeInformativo,
} from '@/utils/mensajes'
import { saveImagen } from '@/utils/files'
import { getPoste, updateCreatePoste } from '@/utils/postes'
import {
  getDefaultBodyMigracion, getMigracion,
  updateCreateMigracion,
} from '@/utils/migracionLuminarias'
import ImagenShowReemplazo from '@/components/componenteImagenActualizar/ImagenShowReemplazo.vue'
import DetalleLevantamientoInicialMigraciones
from '@/components/migraciones/levantamientoInicial/detalleLevantamientoInicialMigraciones.vue'
import { calcularFecha } from '@/utils/fechas'
import ModalVerificacionLevantamiento from '@/components/levantamiento/modalVerificacionLevantamiento.vue'
import { origenes } from '@/utils/origenesImagen'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'FormLevantamiento',
  components: {
    ModalVerificacionLevantamiento,
    DetalleLevantamientoInicialMigraciones,
    ImagenShowReemplazo,
    BOverlay,
    BRow,
    BCol,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Imagen,
    BCard,
    DetailPunto,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    VueGoodTable,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    puntoSeleccionado: {
      type: Object,
      required: true,
    },
    localizacion: {
      type: Object,
      required: true,
    },
    localidad: {
      type: Object,
      required: false,
      default: () => {},
    },
    tecnologias: {
      type: Array,
      required: true,
    },
    potencias: {
      type: Array,
      required: true,
    },
    tiposPoste: {
      type: Array,
      required: true,
    },
    herrajes: {
      type: Array,
      required: true,
    },
    tamanoBrazos: {
      type: Array,
      required: true,
    },
    tipoConexiones: {
      type: Array,
      required: true,
    },
    propiedades: {
      type: Array,
      required: true,
    },
    tipoLamparas: {
      type: Array,
      required: true,
    },
    trabajos: {
      type: Array,
      required: true,
    },
    tipoPostesInstalacion: {
      type: Array,
      required: true,
    },
    metroTriplex: {
      type: Object,
      required: true,
    },
    clasesLampara: {
      type: Array,
      required: true,
    },
    dispositivos: {
      type: Array,
      required: true,
    },
    tamaños: {
      type: Array,
      required: true,
    },
    localidades: {
      type: Array,
      required: true,
    },
    origen: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      modalVerificacion: false,
      rolesAdmin: ['ADMINISTRATOR', 'LEVANTAMIENTO'],
      migracionLuminiaria: null,
      punto: null,
      origenes,
      usuario: JSON.parse(localStorage.getItem('userData')),
      rutaImagenes: '',
      dialogDetallePunto: false,
      loading: false,
      tiposBrazos: [],
      trabajosPoste: [],
      trabajosBrazo: [],
      trabajosLampara: [],
      trabajosConexion: [],
      otrosTrabajos: [],
      dispositivoLampara: [],
      angulosInstalacion: [],
      siNoOptions: [
        { value: 'S', title: 'SI' },
        { value: 'N', title: 'NO' },
      ],
      estados: [],
      showPosteInstalar: false,
      showBrazoInstalar: false,
      showHerrajeInstalar: false,
      showMetrosTriplex: false,
      showMetrosPoda: false,
      imagenPanoramica: null,
      tiposHerrajesInstalados: [],
      required,
      posteTrabajos: [],
      brazoTrabajos: [],
      trabajosOtros: [],
      tamañosPoste: [],
      tamañosBrazo: [],
      puntoDetalle: null,
      tiposTrabajo: [],
      camposTablaSeguimiento: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Comentarios',
          field: 'comentarios',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha / Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      tiposInstalacion: [
        {
          id: 1,
          nombre: 'Normal',
        },
        {
          id: 2,
          nombre: 'Especial',
        },
      ],
    }
  },
  computed: {
    hasLuminariaData() {
      return this.migracionLuminiaria
          && this.migracionLuminiaria.seguimiento
          && this.migracionLuminiaria.seguimiento[0]
          && this.migracionLuminiaria.seguimiento[0].datosLuminaria
    },
    hasNemaData() {
      return this.migracionLuminiaria
          && this.migracionLuminiaria.seguimiento
          && this.migracionLuminiaria.seguimiento[0]
          && this.migracionLuminiaria.seguimiento[0].datosNema
    },
    mostrarBotonGuardar() {
      const detalleInicialEsVacio = !this.punto.detalleInicial

      if (detalleInicialEsVacio) {
        return true
      }

      if (!this.migracionLuminiaria) {
        return true
      }

      const migracionFinalizada = ['MIGRADA', 'FINALIZADO'].includes(this.migracionLuminiaria.estado)

      if (migracionFinalizada) {
        return false
      }

      const esAdministrador = this.usuario.role === 'ADMINISTRATOR'

      return esAdministrador
    },
  },
  watch: {
    posteTrabajos: {
      handler() {
        this.cambioTrabajosPoste()
      },
    },
    brazoTrabajos: {
      handler() {
        this.cambioTrabajosBrazo()
      },
    },
    trabajosOtros: {
      handler() {
        this.cambioOtrosTrabajo()
      },
    },
  },
  async created() {
    this.loading = true
    if (this.puntoSeleccionado.id) {
      this.punto = await getPoste(this.puntoSeleccionado.id)
    } else {
      this.punto = this.puntoSeleccionado
    }

    if (this.punto.detalleInicial) {
      this.migracionLuminiaria = await getMigracion(this.punto.detalleInicial.ticketId)
    }

    this.puntoDetalle = JSON.parse(JSON.stringify(this.punto))
    this.estados = getListadoDetalle(7)
    this.tiposBrazos = getListadoDetalle(5)
    this.tiposHerrajesInstalados = getListadoDetalle(11)
    this.dispositivoLampara = getListadoDetalle(12)
    this.angulosInstalacion = getListadoDetalle(13)
    this.tiposTrabajo = getListadoDetalle(14)
    this.trabajosPoste = this.trabajos.filter(
      obj => obj.tipoTrabajo === 'POSTE',
    )
    this.tamañosPoste = this.tamaños.filter(obj => obj.tipo === 'POSTE')
    this.trabajosBrazo = this.trabajos.filter(
      obj => obj.tipoTrabajo === 'BRAZO',
    )
    this.tamañosBrazo = this.tamaños.filter(obj => obj.tipo === 'BRAZO')
    this.trabajosConexion = this.trabajos.filter(
      obj => obj.tipoTrabajo === 'CONEXION',
    )
    this.trabajosLampara = this.trabajos.filter(
      obj => obj.tipoTrabajo === 'LAMPARA',
    )

    // se quita del trabajosLampara el cambio de lámpara
    this.trabajosLampara = this.trabajosLampara.filter(
      obj => obj.nombre.toLowerCase() !== 'cambio de lámpara',
    )

    this.otrosTrabajos = this.trabajos.filter(
      obj => obj.tipoTrabajo === 'OTROS',
    )

    if (!this.punto.detalleInicial) {
      this.punto.detalleInicial = {
        fechaCreacion: new Date(),
        puntoInicial: JSON.parse(JSON.stringify(this.punto)),
        usuarioInspecciona: {
          id: this.usuario.id,
          nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        },
        poste: {
          numeroPoste: this.punto.numeroPoste,
          tipoPoste: null,
          propiedad: null,
          tamano: null,
          estado: this.estados[0],
          posteInstalar: null,
          trabajos: [],
        },
        brazo: {
          tipo: null,
          tipoHerraje: this.tiposHerrajesInstalados[0],
          estado: this.estados[0],
          brazoInstalar: null,
          tamano: null,
          herrajeInstalar: null,
          trabajos: [],
        },
        conexion: {
          tipo: null,
          cantidadTriplex: 0,
          triplex: this.metroTriplex,
          trabajos: [],
        },
        lampara: {
          dispositivo: null,
          clase: null,
          anguloInstalacion: this.angulosInstalacion[0],
          lamparaInstalar: null,
          tipo: null, // tecnologia
        },
        potencia: this.punto.potencia,
        comentariosGenerales: '',
        numeroPoste: this.punto.numeroPoste,
        localidad: this.localidad,
        metrosPoda: 0,
        tipoInstalacion: this.tiposInstalacion[0], // <- AGREGUE ESTO
      }
    }
    // eslint-disable-next-line prefer-destructuring
    this.punto.detalleInicial.lampara.tipo = this.tecnologias.filter(obj => obj.nombre.toLowerCase()
      .includes('led'))[0]

    // eslint-disable-next-line prefer-destructuring
    this.punto.detalleInicial.lampara.clase = this.clasesLampara.filter(obj => obj.nombre.toLowerCase()
      .includes('led'))[0]

    // eslint-disable-next-line prefer-destructuring
    this.punto.detalleInicial.lampara.dispositivo = this.dispositivos.filter(
      obj => obj.nombre.toLowerCase()
        .includes('lampara'),
    )[0]

    const tecnonologia = this.punto.tecnologiaLampara || this.puntoDetalle.tecnologiaLampara
    // eslint-disable-next-line prefer-destructuring
    this.punto.tecnologiaLampara = this.tecnologias.filter(
      obj => obj.id === tecnonologia,
    )[0]

    if (!this.punto.tecnologiaLampara) {
      // eslint-disable-next-line prefer-destructuring
      this.punto.tecnologiaLampara = this.tecnologias.filter(
        obj => obj.nombre === tecnonologia,
      )[0]
    }

    if (this.punto.tipoPoste) {
      // eslint-disable-next-line prefer-destructuring
      this.punto.detalleInicial.poste.tipoPoste = this.tiposPoste.filter(
        obj => obj.id === this.punto.tipoPoste,
      )[0]
    }

    if (this.punto.potencia) {
      // eslint-disable-next-line prefer-destructuring
      this.punto.detalleInicial.potencia = this.potencias.filter(
        obj => obj.valor === this.punto.potencia,
      )[0]
    }

    const modelo = this.punto.modeloLampara || this.puntoDetalle.modeloLampara
    if (modelo) {
      // eslint-disable-next-line prefer-destructuring
      this.punto.modeloLampara = this.clasesLampara.filter(
        obj => obj.id === modelo,
      )[0]

      if (!this.punto.modeloLampara) {
        // eslint-disable-next-line prefer-destructuring
        this.punto.modeloLampara = this.clasesLampara.filter(
          obj => obj.nombre === modelo,
        )[0]
      }
    }

    const dispositivo = this.punto.dipositivoLampara || this.puntoDetalle.dipositivoLampara
    if (dispositivo) {
      // eslint-disable-next-line prefer-destructuring
      this.punto.dipositivoLampara = this.dispositivos.filter(
        obj => obj.id === dispositivo,
      )[0]

      if (this.punto.dipositivoLampara) {
        // eslint-disable-next-line prefer-destructuring
        this.punto.dipositivoLampara = this.dispositivos.filter(
          obj => obj.nombre === dispositivo,
        )[0]
      }
    }

    if (this.punto.propiedad) {
      // eslint-disable-next-line prefer-destructuring
      this.punto.detalleInicial.poste.propiedad = this.propiedades.filter(
        obj => obj.id === this.punto.propiedad,
      )[0]
    }

    // eslint-disable-next-line prefer-destructuring
    this.punto.detalleInicial.potencia = this.potencias.filter(
      obj => obj.valor === this.punto.potencia,
    )[0]
    if (this.punto && this.punto.detalleInicial && this.punto.detalleInicial.brazo && this.punto.detalleInicial.brazo.trabajos) {
      this.brazoTrabajos = this.punto.detalleInicial.brazo.trabajos
    }
    if (this.punto && this.punto.detalleInicial && this.punto.detalleInicial.otrosTrabajos) {
      this.trabajosOtros = this.punto.detalleInicial.otrosTrabajos
    }
    if (this.punto && this.punto.detalleInicial && this.punto.detalleInicial.poste && this.punto.detalleInicial.poste.trabajos) {
      this.posteTrabajos = this.punto.detalleInicial.poste.trabajos
    }
    if (this.punto && this.punto.detalleInicial && this.punto.detalleInicial.conexion && this.punto.detalleInicial.conexion.cantidadTriplex > 0) {
      this.showMetrosTriplex = true
    }
    if (this.migracionLuminiaria && this.migracionLuminiaria.levantamientoPunto && this.migracionLuminiaria.levantamientoPunto.puntoInicial) {
      this.punto.dipositivoLampara = this.migracionLuminiaria.levantamientoPunto.puntoInicial.dipositivoLampara
      this.punto.modeloLampara = this.migracionLuminiaria.levantamientoPunto.puntoInicial.modeloLampara
      this.punto.tecnologiaLampara = this.migracionLuminiaria.levantamientoPunto.puntoInicial.tecnologiaLampara
    }
    if (this.migracionLuminiaria && this.migracionLuminiaria.levantamientoPunto && this.migracionLuminiaria.levantamientoPunto.lampara) {
      // eslint-disable-next-line no-self-assign
      this.punto.detalleInicial.lampara.dispositivo = this.migracionLuminiaria.levantamientoPunto.lampara.dispositivo
      this.punto.detalleInicial.lampara.clase = this.migracionLuminiaria.levantamientoPunto.lampara.clase
      this.punto.detalleInicial.lampara.tipo = this.migracionLuminiaria.levantamientoPunto.lampara.tipo
    }
    this.loading = false
  },
  methods: {
    abrirModalVerificacion() {
      this.modalVerificacion = true
    },
    notificarCambio() {
      this.modalVerificacion = false
      this.$emit('notificar-cambio', this.punto)
    },
    mostrarTabSeguimiento() {
      if (this.migracionLuminiaria) {
        const estadosActivos = ['MIGRADA', 'FINALIZADO']
        return estadosActivos.includes(this.migracionLuminiaria.estado)
      }
      return false
    },
    irRuta(ticket) {
      if (!ticket) return
      if (ticket.latitud === '' || ticket.longitud === '') return
      const url = `https://maps.google.com/?q=${ticket.latitud},${ticket.longitud}`
      window.open(url, '_blank')
    },
    validarBrazoTrabajos() {
      if (
        this.migracionLuminiaria
          && this.migracionLuminiaria.trabajos
          && this.migracionLuminiaria.trabajos.brazo
      ) {
        const trabajosBrazoSeleccionados = this.punto.detalleInicial.brazo.trabajos
        const trabajosBrazoMigracion = this.migracionLuminiaria.trabajos.brazo.trabajos
        const mapeoPunto = this.punto.detalleInicial

        // Verificar si no hay trabajos seleccionados en el punto
        if (trabajosBrazoSeleccionados.length === 0) {
          // Si no hay trabajos seleccionados en el punto, limpiar los trabajos de migración
          this.migracionLuminiaria.trabajos.brazo.trabajos = []
          return
        }
        trabajosBrazoSeleccionados.forEach(trabajo => {
          const trabajoEnMigracion = trabajosBrazoMigracion.find(t => t.nombre === trabajo.nombre)
          if (!trabajoEnMigracion) {
            this.migracionLuminiaria.trabajos.brazo.trabajos.push({
              ...trabajo,
              seguimiento: [],
              datosBrazo: {
                brazoInstalar: mapeoPunto.brazo.brazoInstalar ? mapeoPunto.brazo.brazoInstalar : {},
                estado: mapeoPunto.brazo.estado ? mapeoPunto.brazo.estado : {},
                herrajeInstalar: mapeoPunto.brazo.herrajeInstalar ? mapeoPunto.brazo.herrajeInstalar : {},
                tamano: mapeoPunto.brazo.tamano ? mapeoPunto.brazo.tamano : {},
                tipo: mapeoPunto.brazo.tipo ? mapeoPunto.brazo.tipo : {},
                tipoHerraje: mapeoPunto.brazo.tipoHerraje ? mapeoPunto.brazo.tipoHerraje : {},
              },
            })
          }
        })
        trabajosBrazoMigracion.forEach(trabajo => {
          const trabajoEnPunto = trabajosBrazoSeleccionados.find(t => t.nombre === trabajo.nombre)
          if (!trabajoEnPunto) {
            const index = this.migracionLuminiaria.trabajos.brazo.trabajos.indexOf(trabajo)
            if (index !== -1) {
              this.migracionLuminiaria.trabajos.brazo.trabajos.splice(index, 1)
            }
          }
        })
      }
    },
    validarLamparaTrabajos() {
      if (
        this.migracionLuminiaria
          && this.migracionLuminiaria.trabajos
          && this.migracionLuminiaria.trabajos.lampara
      ) {
        const trabajosLamparaSeleccionados = this.punto.detalleInicial.lampara.trabajos
        const trabajosLamparaMigracion = this.migracionLuminiaria.trabajos.lampara.trabajos
        const mapeoPunto = this.punto.detalleInicial
        if (trabajosLamparaSeleccionados.length === 0) {
          const cambioLamparaMigracion = trabajosLamparaMigracion.find(t => t.nombre === 'CAMBIO DE LÁMPARA')
          if (cambioLamparaMigracion) {
            this.migracionLuminiaria.trabajos.lampara.trabajos = [cambioLamparaMigracion]
          } else {
            this.migracionLuminiaria.trabajos.lampara.trabajos = []
          }
          return
        }
        trabajosLamparaSeleccionados.forEach(trabajo => {
          const trabajoEnMigracion = trabajosLamparaMigracion.find(t => t.nombre === trabajo.nombre)
          if (!trabajoEnMigracion) {
            this.migracionLuminiaria.trabajos.lampara.trabajos.push({
              ...trabajo,
              seguimiento: [],
              datosLampara: {
                anguloInstalacion: mapeoPunto.lampara.anguloInstalacion ? mapeoPunto.lampara.anguloInstalacion : {},
                clase: mapeoPunto.lampara.clase ? mapeoPunto.lampara.clase : {},
                dispositivo: mapeoPunto.lampara.dispositivo ? mapeoPunto.lampara.dispositivo : {},
                lamparaInstalar: mapeoPunto.lampara.lamparaInstalar ? mapeoPunto.lampara.lamparaInstalar : {},
                tipo: mapeoPunto.lampara.tipo ? mapeoPunto.lampara.tipo : {},
              },
            })
          }
        })
        trabajosLamparaMigracion.forEach(trabajo => {
          const trabajoEnPunto = trabajosLamparaSeleccionados.find(t => t.nombre === trabajo.nombre)
          if (trabajo.nombre !== 'CAMBIO DE LÁMPARA' && !trabajoEnPunto) {
            const index = this.migracionLuminiaria.trabajos.lampara.trabajos.indexOf(trabajo)
            if (index !== -1) {
              this.migracionLuminiaria.trabajos.lampara.trabajos.splice(index, 1)
            }
          }
        })
      }
    },
    validarPosteTrabajos() {
      if (
        this.migracionLuminiaria
          && this.migracionLuminiaria.trabajos
          && this.migracionLuminiaria.trabajos.poste
      ) {
        const trabajosPosteSeleccionados = this.punto.detalleInicial.poste.trabajos
        const trabajosPosteMigracion = this.migracionLuminiaria.trabajos.poste.trabajos
        const mapeo = this.punto.detalleInicial
        if (trabajosPosteSeleccionados.length === 0) {
          this.migracionLuminiaria.trabajos.poste.trabajos = []
          return
        }
        trabajosPosteSeleccionados.forEach(trabajo => {
          const trabajoEnMigracion = trabajosPosteMigracion.find(t => t.nombre === trabajo.nombre)
          if (!trabajoEnMigracion) {
            this.migracionLuminiaria.trabajos.poste.trabajos.push({
              ...trabajo,
              seguimiento: [],
              datosPoste: {
                estado: mapeo.poste.estado ? mapeo.poste.estado : {},
                numeroPoste: mapeo.poste.numeroPoste ? mapeo.poste.numeroPoste : '',
                posteInstalar: mapeo.poste.posteInstalar ? mapeo.poste.posteInstalar : {},
                propiedad: mapeo.poste.propiedad ? mapeo.poste.propiedad : {},
                tamano: mapeo.poste.tamano ? mapeo.poste.tamano : {},
                tipoPoste: mapeo.poste.tipoPoste ? mapeo.poste.tipoPoste : {},
              },
            })
          }
        })
        trabajosPosteMigracion.forEach(trabajo => {
          const trabajoEnPunto = trabajosPosteSeleccionados.find(t => t.nombre === trabajo.nombre)
          if (!trabajoEnPunto) {
            const index = this.migracionLuminiaria.trabajos.poste.trabajos.indexOf(trabajo)
            if (index !== -1) {
              this.migracionLuminiaria.trabajos.poste.trabajos.splice(index, 1)
            }
          }
        })
      }
    },
    validarOtrosTrabajos() {
      if (
        this.migracionLuminiaria
          && this.migracionLuminiaria.trabajos
          && this.migracionLuminiaria.trabajos.otrosTrabajos
      ) {
        const otrosTrabajosSeleccionados = this.punto.detalleInicial.otrosTrabajos
        const otrosTrabajosMigracion = this.migracionLuminiaria.trabajos.otrosTrabajos.trabajos
        if (otrosTrabajosSeleccionados.length === 0) {
          this.migracionLuminiaria.trabajos.otrosTrabajos.trabajos = []
          return
        }
        otrosTrabajosSeleccionados.forEach(trabajo => {
          const trabajoEnMigracion = otrosTrabajosMigracion.find(t => t.nombre === trabajo.nombre)
          if (!trabajoEnMigracion) {
            this.migracionLuminiaria.trabajos.otrosTrabajos.trabajos.push({
              ...trabajo,
              seguimiento: [],
            })
          }
        })
        otrosTrabajosMigracion.forEach(trabajo => {
          const trabajoEnPunto = otrosTrabajosSeleccionados.find(t => t.nombre === trabajo.nombre)
          if (!trabajoEnPunto) {
            const index = this.migracionLuminiaria.trabajos.otrosTrabajos.trabajos.indexOf(trabajo)
            if (index !== -1) {
              this.migracionLuminiaria.trabajos.otrosTrabajos.trabajos.splice(index, 1)
            }
          }
        })
      }
    },
    validarConexionTrabajos() {
      if (
        this.migracionLuminiaria
          && this.migracionLuminiaria.trabajos
          && this.migracionLuminiaria.trabajos.conexion
      ) {
        const trabajosConexionSeleccionados = this.punto.detalleInicial.conexion.trabajos
        const trabajosConexionMigracion = this.migracionLuminiaria.trabajos.conexion.trabajos
        const mapeo = this.punto.detalleInicial
        if (trabajosConexionSeleccionados.length === 0) {
          this.migracionLuminiaria.trabajos.conexion.trabajos = []
          return
        }
        trabajosConexionSeleccionados.forEach(trabajo => {
          const trabajoEnMigracion = trabajosConexionMigracion.find(t => t.nombre === trabajo.nombre)
          if (!trabajoEnMigracion) {
            this.migracionLuminiaria.trabajos.conexion.trabajos.push({
              ...trabajo,
              seguimiento: [],
              datosConexion: {
                cantidadTriplex: mapeo.conexion.cantidadTriplex ? mapeo.conexion.cantidadTriplex : '',
                tipo: mapeo.conexion.tipo ? mapeo.conexion.tipo : {},
                triplex: mapeo.conexion.triplex ? mapeo.conexion.triplex : '',
              },
            })
          }
        })
        trabajosConexionMigracion.forEach(trabajo => {
          const trabajoEnPunto = trabajosConexionSeleccionados.find(t => t.nombre === trabajo.nombre)
          if (!trabajoEnPunto) {
            const index = this.migracionLuminiaria.trabajos.conexion.trabajos.indexOf(trabajo)
            if (index !== -1) {
              this.migracionLuminiaria.trabajos.conexion.trabajos.splice(index, 1)
            }
          }
        })
      }
    },
    isMigrada() {
      if (!this.rolesAdmin.includes(this.usuario.role)) return false

      return this.migracionLuminiaria && (this.migracionLuminiaria.estado === 'MIGRADA' || this.migracionLuminiaria.estado === 'FINALIZADO')
    },
    async nuevaImagen(newImage) {
      const isMobile = window.screen.width <= 760
      const finalObjImagen = await this.guardarImagenInspeccion(newImage)
      finalObjImagen.fechaHora = this.punto.detalleInicial.imagenPanoramica.fechaHora
      finalObjImagen.localization = this.punto.detalleInicial.imagenPanoramica.localization
      finalObjImagen.isMobile = isMobile

      // reemplazar en el objeto migración
      this.migracionLuminiaria.levantamientoPunto.imagenPanoramica = finalObjImagen
      // reemplazar en el punto de iluminación
      this.punto.detalleInicial.imagenPanoramica = finalObjImagen

      const index = this.punto.imagenes.findIndex(objeto => objeto.tipo === finalObjImagen.tipo)
      if (index !== -1) {
        this.punto.imagenes[index] = finalObjImagen
      }

      const result = this.guadarMigracionPoste({
        id: this.migracionLuminiaria.id,
        levantamientoPunto: this.migracionLuminiaria.levantamientoPunto,
      }, 1, {
        id: this.punto.id,
        detalleInicial: this.punto.detalleInicial,
        imagenes: this.punto.imagenes,
      })
      mensajeInformativo('Fotografía Actualizada', 'Actualizado Correctamente')
      if (!result) {
        mensajeError('Error actualizando la Imagen!')
      }
    },
    validacionesFormulario() {
      if (!this.imagenPanoramica && !this.migracionLuminiaria) {
        mensajeError('No se tiene una imagen cargada!')
        return false
      }

      if (
        this.showMetrosTriplex
        && this.punto.detalleInicial.conexion.cantidadTriplex === 0
      ) {
        mensajeError(
          'La cantidad de Metros de Linea Triplex 240V debe ser mayor a 0!',
        )
        return false
      }

      if (this.showMetrosPoda && this.punto.detalleInicial.metrosPoda === 0) {
        mensajeError('La cantidad de Metros de Poda debe ser mayor a 0!')
        return false
      }
      return true
    },
    async guardarImagenInspeccion(imagen) {
      const urlBucket = `levantamiento-inicial/${this.punto.id}`
      const finalObjImg = await saveImagen(
        imagen,
        urlBucket,
        this.usuario,
        'Imagen Panoramica Inicial',
        this.localizacion,
      )
      if (!finalObjImg) {
        mensajeError('Error al guardar la imagen, intente nuevamente!')
      }
      return finalObjImg
    },
    async guadarMigracionPoste(migracion, tipo, punto = null) {
      const response = await updateCreateMigracion(
        migracion,
        tipo,
      )

      if (!response) {
        this.loading = false
        mensajeError('Error guardando el ticket, intente nuevamente!')
        return false
      }

      if (!punto) {
        if (!this.punto.detalleInicial.ticketId) this.punto.detalleInicial.ticketId = response.id
        this.punto.tecnologiaLampara = this.punto.tecnologiaLampara.id
        this.punto.modeloLampara = this.punto.modeloLampara.id
      } else if (punto.ingresoManual) {
        this.punto.tecnologiaLampara = this.punto.tecnologiaLampara.id
        this.punto.modeloLampara = this.punto.modeloLampara.id
      }

      const update = await updateCreatePoste(punto || this.punto, 1)

      this.loading = false
      if (!update) {
        mensajeError(
          'Error actualizando el punto de iluminación, intente nuevamente!',
        )
        return false
      }
      return true
    },
    guardarInspeccion() {
      this.$refs.formulario.validate().then(async success => {
        if (success) {
          if (!this.validacionesFormulario()) return

          if (!this.punto.id) {
            const confirm = await mensajeConfirmar(
              'Ingreso Manual',
              '¿Está seguro de guardar el punto de iluminación? Este se ingresará a la base de datos y no podrá eliminarse',
              'info',
            )
            if (!confirm) return
            this.loading = true
            const detalleInicial = JSON.parse(
              JSON.stringify(this.punto.detalleInicial),
            )
            this.punto.tecnologiaLampara = this.punto.detalleInicial.lampara.tipo.id
            this.punto.modeloLampara = this.punto.detalleInicial.lampara.clase.id
            this.punto.tipoPoste = this.punto.detalleInicial.poste.tipoPoste.id
            this.punto.numeroPoste = this.punto.detalleInicial.poste.numeroPoste
            this.punto.propiedad = this.punto.detalleInicial.poste.propiedad.id
            this.punto.dipositivoLampara = this.punto.detalleInicial.lampara.dispositivo.id
            this.punto.localidad = this.punto.detalleInicial.localidad.id
            this.punto.latitud = `${this.localizacion.lat}`
            this.punto.longitud = `${this.localizacion.lng}`
            this.punto.observaciones = this.punto.detalleInicial.comentariosGenerales
            this.punto.potencia = this.punto.detalleInicial.potencia.valor

            if (!this.punto.serieLampara || this.punto.serieLampara === '') {
              this.punto.serieLampara = 'SIN SERIE'
            }
            delete this.punto.detalleInicial
            const newPunto = await updateCreatePoste(this.punto, 2)
            if (!newPunto) {
              this.loading = false
              mensajeError(
                'Error ingresando el punto de iluminación, intente nuevamente!',
              )
              return
            }
            // se asigna el nuevo ID
            this.punto.id = newPunto.id
            this.punto.detalleInicial = detalleInicial
          } else {
            let mensaje = '¿Está seguro de guardar la inspección? Esto actualizará el punto de iluminación e ingresará un ticket de cambio de luminaria'
            if (this.migracionLuminiaria) mensaje = '¿Está seguro de editar la inspección? Esto actualizará el punto de iluminación y modificará el ticket de cambio de luminaria'

            const confirm = await mensajeConfirmar(
              'Ingreso de Datos',
              mensaje,
              'info',
            )
            if (!confirm) return
            this.loading = true
          }
          if (!this.migracionLuminiaria) {
            const finalObjImg = await this.guardarImagenInspeccion(this.imagenPanoramica)
            if (!finalObjImg) return
            this.punto.detalleInicial.imagenPanoramica = finalObjImg

            if (!this.punto.imagenes) {
              this.punto.imagenes = []
            }

            this.punto.imagenes.push(finalObjImg)
          }

          const detalleInicial = JSON.parse(
            JSON.stringify(this.punto.detalleInicial),
          )
          delete this.punto.detalleInicial
          detalleInicial.puntoInicial = JSON.parse(JSON.stringify(this.punto))
          this.punto.detalleInicial = detalleInicial

          // se actualizan algunos valores del poste

          this.punto.tecnologiaLampara = this.punto.detalleInicial.lampara.tipo.id
          this.punto.modeloLampara = this.punto.detalleInicial.lampara.clase.id
          this.punto.tipoPoste = this.punto.detalleInicial.poste.tipoPoste.id
          this.punto.numeroPoste = this.punto.detalleInicial.poste.numeroPoste
          this.punto.propiedad = this.punto.detalleInicial.poste.propiedad.id
          this.punto.potencia = this.punto.detalleInicial.potencia.valor
          this.punto.dipositivoLampara = this.punto.detalleInicial.lampara.dispositivo.id

          if (!this.punto.detalleInicial.poste.trabajos) this.punto.detalleInicial.poste.trabajos = []
          if (!this.punto.detalleInicial.brazo.trabajos) this.punto.detalleInicial.brazo.trabajos = []
          if (!this.punto.detalleInicial.lampara.trabajos) this.punto.detalleInicial.lampara.trabajos = []
          if (!this.punto.detalleInicial.conexion.trabajos) this.punto.detalleInicial.conexion.trabajos = []
          if (!this.punto.detalleInicial.otrosTrabajos) this.punto.detalleInicial.otrosTrabajos = []
          delete this.punto.latitud
          delete this.punto.longitud
          if (!this.punto.imagenes) {
            this.punto.imagenes = []
          }

          let migracionObj = this.migracionLuminiaria
          if (!this.migracionLuminiaria) {
            migracionObj = getDefaultBodyMigracion(this.punto, this.usuario)
            migracionObj.tipoInstalacion = this.punto.detalleInicial.tipoInstalacion
            migracionObj.latitud = `${this.localizacion.lat}`
            migracionObj.longitud = `${this.localizacion.lng}`
          } else {
            delete migracionObj.fechaCreacion
          }
          this.validarBrazoTrabajos()
          this.validarLamparaTrabajos()
          this.validarPosteTrabajos()
          this.validarOtrosTrabajos()
          this.validarConexionTrabajos()

          migracionObj.levantamientoPunto = this.punto.detalleInicial

          const result = this.guadarMigracionPoste(migracionObj, this.punto.detalleInicial.ticketId ? 1 : 2)
          if (!result) return

          mensajeInformativo(
            'Ingreso de Levantamiento',
            'Punto y Ticket actualizados correctamente!',
          )
          this.$emit('cerrar-dialogo-levantamiento', this.punto)
        }
      })
    },
    setImagenPanoramica(file) {
      this.imagenPanoramica = file
    },
    verDetallePoste() {
      this.ocultarDialogoPoste()
      this.dialogDetallePunto = true
    },
    ocultarDialogoPoste() {
      this.dialogDetallePunto = false
    },
    cambioTrabajosPoste() {
      this.punto.detalleInicial.poste.trabajos = this.posteTrabajos
      if (!this.punto.detalleInicial.poste.trabajos) {
        this.punto.detalleInicial.poste.posteInstalar = null
        this.showPosteInstalar = false
      } else if (
        this.punto.detalleInicial.poste.trabajos.some(obj => obj.nombre.toUpperCase().includes('CAMBIO'))
      ) {
        this.showPosteInstalar = true
      } else {
        this.punto.detalleInicial.poste.posteInstalar = null
        this.showPosteInstalar = false
      }
    },
    cambioTrabajoConexion() {
      if (!this.punto.detalleInicial.conexion.trabajos) {
        this.punto.detalleInicial.conexion.cantidadTriplex = 0
        this.showMetrosTriplex = false
      } else if (
        this.punto.detalleInicial.conexion.trabajos.some(obj => obj.nombre.toUpperCase().includes('TRIPLEX'))
      ) {
        this.showMetrosTriplex = true
      } else {
        this.punto.detalleInicial.conexion.cantidadTriplex = 0
        this.showMetrosTriplex = false
      }
    },
    cambioTrabajosBrazo() {
      this.punto.detalleInicial.brazo.trabajos = this.brazoTrabajos
      if (!this.punto.detalleInicial.brazo.trabajos) {
        this.punto.detalleInicial.brazo.brazoInstalar = null
        this.punto.detalleInicial.brazo.herrajeInstalar = null
        this.showBrazoInstalar = false
        this.showHerrajeInstalar = false
      } else {
        this.showBrazoInstalar = this.punto.detalleInicial.brazo.trabajos.some(
          obj => obj.nombre.toUpperCase().includes('BRAZO'),
        )
        this.showHerrajeInstalar = this.punto.detalleInicial.brazo.trabajos.some(obj => obj.nombre.toUpperCase().includes('HERRAJE'))

        if (!this.showBrazoInstalar) this.punto.detalleInicial.brazo.brazoInstalar = null
        if (!this.showHerrajeInstalar) this.punto.detalleInicial.brazo.herrajeInstalar = null
      }
    },
    cambioOtrosTrabajo() {
      this.punto.detalleInicial.otrosTrabajos = this.trabajosOtros
      if (!this.punto.detalleInicial.otrosTrabajos) {
        this.punto.detalleInicial.metrosPoda = 0
        this.showMetrosPoda = false
      } else if (
        this.punto.detalleInicial.otrosTrabajos.some(obj => obj.nombre.toUpperCase().includes('PODA'))
      ) {
        this.showMetrosPoda = true
      } else {
        this.punto.detalleInicial.metrosPoda = 0
        this.showMetrosPoda = false
      }
    },
    formatFecha(item) {
      return calcularFecha(item)
    },
    cerrarModal() {
      this.$emit('cerrar-dialogo-levantamiento')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.inputs-desabilitados input,
.inputs-desabilitados textarea,
.inputs-desabilitados .v-select {
  pointer-events: none;
  background-color: #f0f0f0;
  color: #999;
}
</style>
