<template>
  <div>
    <b-row>
      <b-col
        v-if="trabajo && trabajo.nombre === 'ÁNGULO'"
        cols="12"
      >
        <span class="text-primary">Ángulo Realizado:</span>
        <b-form-input
          :value="trabajo && trabajo.datosLampara.anguloInstalacion.nombre"
          disabled
        />
        <br>
      </b-col>
      <b-col
        v-if="trabajo && trabajo.nombre === 'CAMBIO DE BRAZO'"
        cols="12"
      >
        <b-form-group>
          <h5 class="text-primary">
            Brazo a Instalar:
          </h5>
          <b-form-input
            :value="trabajo && trabajo.datosBrazo.brazoInstalar.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="trabajo && trabajo.nombre === 'CAMBIO HERRAJE'"
        cols="12"
      >
        <b-form-group>
          <h5 class="text-primary">
            Herraje a Instalar:
          </h5>
          <b-form-input
            :value="trabajo && trabajo.datosBrazo.herrajeInstalar.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="trabajo && trabajo.nombre === 'INSTALACIÓN DE TRIPLEX'"
        cols="12"
      >
        <b-form-group>
          <h5 class="text-primary">
            Metros de Triplex a Instalados:
          </h5>
          <b-form-input
            :value="trabajo && trabajo.datosConexion.cantidadTriplex"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="trabajo && trabajo.nombre === 'REQUIERE CAMBIO'"
        cols="12"
      >
        <b-form-group>
          <h5 class="text-primary">
            Poste Instalado:
          </h5>
          <b-form-input
            :value="trabajo && trabajo.datosPoste.posteInstalar.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <vue-good-table
          :columns="columnsImagenes"
          :rows="trabajo.seguimiento"
          style-class="vgt-table condensed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'nombre'" />
            <span v-else-if="props.column.field == 'detalle'">
              <ImagenShowReemplazo
                :importado="'trabajosMigraciones'"
                :posicion-imagen="props.index"
                :clave="'seguimiento'"
                :ticket="migracion"
                :objeto-seleccionado="trabajo"
                :origen="origenes.migraciones"
                :index-trabajo="indexTrabajo"
                @actualizar="cerrarModal"
              />
              <!--b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-1
                variant="gradient-primary"
                @click="mostrarDetalle(props)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button-->
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import {
  BButton, BCol, BFormInput, BRow, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ImagenShowReemplazo from '@/components/componenteImagenActualizar/ImagenShowReemplazo.vue'
import { origenes } from '@/utils/origenesImagen'

export default {
  components: {
    ImagenShowReemplazo,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    migracion: {
      type: Object,
      required: true,
    },
    trabajo: {
      type: Object,
      required: true,
    },
    indexTrabajo: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      columnsImagenes: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Comentarios',
          field: 'comentarios',
          width: '40vw',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    origenes() {
      return origenes
    },
    migracionTrabajoConId() {
      // Crea una copia del objeto trabajo
      const trabajoConId = { ...this.trabajo }
      // Llama a la función para agregar el id de migración al trabajo
      this.agregarIdMigracionAlTrabajo()
      console.log(trabajoConId)
      // Retorna el objeto trabajo modificado
      return trabajoConId
    },
  },
  methods: {
    agregarIdMigracionAlTrabajo() {
      // Verifica que la migración y el trabajo no estén vacíos
      if (this.migracion && this.trabajo) {
        // Asigna el id de migración al trabajo
        this.$set(this.trabajo, 'idMigracion', this.migracion.id)
        // Si el objeto 'trabajo' no tiene una propiedad 'idMigracion', esta línea la agregará
      }
    },
    mostrarDetalle(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
    },
    cerrarModal() {
      this.$emit('actualizar')
    },
  },
}

</script>
