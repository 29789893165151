<template>
  <div>
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      v-b-tooltip.hover.v-danger
      variant="danger"
      placeholder="Reporte"
      title="Reporte de Accesorios Requeridos"
      class="btn-icon"
      style="height: 40px; width: 40px;"
      block
      @click="showReporte"
    >
      <feather-icon
        size="18"
        icon="BarChartIcon"
      />&nbsp;
    </b-button>

    <b-modal
      v-model="dialog"
      hide-footer
      modal-class="modal-primary"
      centered
      size="xl"
      title="Reporte de Accesorios Requeridos"
    >
      <b-card class="card-poste">
        <reporte-levantamiento />
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BCard,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import reporteLevantamiento from '@/components/levantamiento/reporteLevantamiento.vue'

export default {
  name: 'ReporteLevantamientoButton',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    reporteLevantamiento,
    BModal,
    BButton,
    BCard,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    async showReporte() {
      this.dialog = true
    },
  },
}
</script>

<style scoped>

</style>
