<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-tabs justified>
            <b-tab
              active
              title="Lámpara"
            >
              <b-row>
                <b-col cols="12">
                  <chart-barras
                    :items="lamparas"
                    :show-footer="true"
                    :title="'Tipos de Lámparas'"
                    :subtitle="'Información en curso'"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Poste">
              <b-row>
                <b-col cols="12">
                  <chart-donunt
                    :items="postes"
                    :show-footer="true"
                    :title="'Tipos de Postes'"
                    :subtitle="'Información en curso'"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Brazo">
              <b-row>
                <b-col cols="12">
                  <chart-barras
                    :items="brazos"
                    :show-footer="true"
                    :title="'Tipos de Brazos'"
                    :subtitle="'Información en curso'"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Herraje">
              <b-row>
                <b-col cols="12">
                  <chart-donunt
                    :items="herrajes"
                    :show-footer="true"
                    :title="'Tipos de Herrajes'"
                    :subtitle="'Información en curso'"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              title="Adicionales"
            >
              <b-overlay
                :show="loading"
              >
                <b-row>
                  <b-col cols="6">
                    <b-card>
                      <div>
                        <h3 class="hContadorTitulo">
                          ADAPTADORES REQUERIDOS
                        </h3>
                        <p class="hContador">
                          {{ totalAdaptadores }}
                        </p>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col cols="6">
                    <b-card>
                      <h3 class="hContadorTitulo">
                        METROS DE CABLE REQUERIDOS
                      </h3>
                      <p class="hContador">
                        {{ conexiones[0] && conexiones[0].triplex }}
                      </p>
                    </b-card>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-end">
      <b-col cols="2">
        <reporte-lampara-levantamiento
          class="d-flex justify-content-end"
          :datos-lampara-levantamiento="lamparas"
          :datos-poste-levantamiento="postes"
          :datos-brazo-levantamiento="brazos"
          :datos-herraje-levantamiento="herrajes"
          :datos-conexion-levantamiento="conexiones"
          :datos-adaptadores-requeridos="totalAdaptadores"
          :text-button="`Descargar Reporte`"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BCol, BRow, BTab, BTabs, BOverlay,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { reporteAccesoriosRequeridos } from '@/utils/migracionLuminarias'
import ReporteLamparaLevantamiento from '@/utils/reportes/reporteAccesoriosRequeridos/reporteLamparaLevantamiento.vue'
import ChartBarras from '@/components/levantamiento/charts/chartBarrasLevantamiento.vue'
import ChartDonunt from '@/components/levantamiento/charts/chatDonuntLevantamiento.vue'
import { getPostesFilter } from '@/utils/postes'

export default {
  components: {
    ChartBarras,
    ChartDonunt,
    ReporteLamparaLevantamiento,
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BOverlay,
  },
  data() {
    return {
      loading: false,
      totalAdaptadores: 0,
      rows: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
      lamparas: [],
      postes: [],
      brazos: [],
      herrajes: [],
      conexiones: [],
      adaptadores: [],
    }
  },
  async created() {
    this.rows = []
    await this.loadReport()
    await this.obtenerAdaptadores()
  },
  methods: {
    async loadReport() {
      const filtro = {
        companyId: this.usuario.company.id,
      }
      this.rows = await reporteAccesoriosRequeridos(filtro)
      this.lamparas = this.rows.lampara
      this.postes = this.rows.poste
      this.brazos = this.rows.brazo
      this.herrajes = this.rows.herraje
      this.conexiones = this.rows.conexion
    },
    async obtenerAdaptadores() {
      this.loading = true
      const filter = {
        where: {
          and: [
            { company: this.usuario.company.id },
            { detalleInicial: { neq: null } },
          ],
        },
        fields: {
          detalleInicial: true,
        },
      }

      this.adaptadores = await getPostesFilter(filter)
      // eslint-disable-next-line no-unused-vars
      let contador = 0

      this.adaptadores.forEach(poste => {
        if (poste.detalleInicial.lampara && poste.detalleInicial.lampara.trabajos) {
          const { trabajos } = poste.detalleInicial.lampara
          if (trabajos.some(trabajo => trabajo.id === 21)) {
            // eslint-disable-next-line no-plusplus
            contador++
          }
        }
      })
      this.totalAdaptadores = contador
      this.loading = false
    },
  },
}
</script>

<style scoped>
.hContadorTitulo {
  color: #006394;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
.hContador {
  display: flex;
  font-weight: bold;
  justify-content: center;
  font-size: xxx-large;
  margin-bottom: -35px;
  color: #29A0B1;
}
</style>
