<template>
  <div>
    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="trabajos"
          style-class="vgt-table condensed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'nombre'">{{ props.row.nombre.toUpperCase() }}</span>
            <span v-else-if="props.column.field == 'detalle'">
              <!--b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-1
                variant="gradient-primary"
                @click="mostrarDetalle(props)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button-->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-1
                variant="gradient-primary"
                @click="mostrarDetalle(props)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <b-modal
      v-model="showModalDetalle"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-only
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      :title="`Detalle de ${trabajoSeleccionado.nombre}`"
      style="max-height: 200px"
    >
      <DetalleTrabajoRealizado
        :trabajo="trabajoSeleccionado"
        :index-trabajo="indexTrabajoSeleccionado"
        :migracion="migracion"
        @actualizar="cerrarModal"
      />
    </b-modal>
    <div>
      <b-modal
        v-model="verDialogoDocumento"
        ok-only
        ok-variant="primary"
        ok-title="Aceptar"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle de la Imagen"
      >
        <b-row>
          <b-col cols="12">
            <vue-good-table
              :columns="columnsImagenes"
              :rows="imagenes"
              style-class="vgt-table condensed"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'nombre'" />
                <span v-else-if="props.column.field == 'detalle'">
                  <!--b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-1
                variant="gradient-primary"
                @click="mostrarDetalle(props)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button-->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-1
                    variant="gradient-primary"
                    @click="mostrarImagenes(props.row)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div>
      <b-modal
        v-model="verDialogoDocumentoImagenes"
        ok-only
        ok-variant="primary"
        ok-title="Aceptar"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle de la Imagen"
      >
        <b-card-text style="display: flex; justify-content: center">
          <b-img
            :src="urlImagen"
            fluid
            alt="Fluid-grow image')"
            style="max-height: 700px;"
          />
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import {
  BButton, BCol, BRow, BModal, BCardText, BImg, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DetalleTrabajoRealizado from '@/components/levantamiento/DetalleTrabajoRealizado.vue'

export default {
  components: {
    DetalleTrabajoRealizado,
    BCol,
    BRow,
    BButton,
    BCardText,
    VueGoodTable,
    BImg,
    BModal,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    migracion: {
      type: Object,
      required: true,
    },
    trabajos: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imagenes: [],
      urlImagen: '',
      verDialogoDocumento: false,
      verDialogoDocumentoImagenes: false,
      trabajoSeleccionado: {},
      showModalDetalle: false,
      columns: [
        {
          label: 'Trabajo',
          field: 'nombre',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      columnsImagenes: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      indexTrabajoSeleccionado: '',
    }
  },
  methods: {
    mostrarDetalle(row) {
      this.trabajoSeleccionado = row.row
      this.indexTrabajoSeleccionado = row.index
      this.showModalDetalle = true
    },
    cerrarModal() {
      this.showModalDetalle = false
      this.$emit('actualizar')
    },
    mostrarImagenes(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
      this.verDialogoDocumentoImagenes = true
    },
  },
}
</script>
