<template>
  <div v-if="showGrafica">
    <b-row>
      <b-col :cols="cols">
        <div id="grafica_lamparas">
          <donut-chart
            :key="graficaData.counter"
            :subtitle="subtitle"
            :title="title"
            :data="graficaData.data"
            :chart-options="graficaData.options"
            :height="450"
          />
        </div>
      </b-col>
      <b-col
        v-if="showFooter"
        :cols="cols"
      >
        <br>
        <br>
        <h4>Estadísticas:</h4>
        <br>
        <table>
          <tr>
            <th style="width: 60%">
              Accesorio
            </th>
            <th style="width: 20%">
              Total
            </th>
            <th style="width: 20%">
              &nbsp;
            </th>
          </tr>
          <tr
            v-for="(item,index) in items"
            :key="index"
          >
            <td>{{ item.nombre ? item.nombre.toUpperCase() : 'NO REQUIERE CAMBIO' }}</td>
            <td><b>{{ item.total }}</b></td>
            <td>
              <vue-apex-charts
                type="radialBar"
                height="120"
                width="120"
                :options="chartData[index].options"
                :series="chartData[index].series"
              />
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import DonutChart from '@/components/charts/DonutChart.vue'
import { basicChartDonut, chartDonutOptions } from '@/utils/charts'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    DonutChart,
    BRow,
    BCol,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      total: 0,
      cols: window.screen.width <= 850 ? 12 : 6,
      showGrafica: false,
      graficaData: {
        data: [],
        options: {},
        counter: 0,
      },
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.success],
      chartData: [],
    }
  },
  watch: {
    items: {
      deep: true,
      handler(newVal) {
        this.actualizarGrafica()
      },
    },
  },
  mounted() {
    this.actualizarGrafica()
  },
  methods: {
    actualizarGrafica() {
      this.showGrafica = false
      // eslint-disable-next-line no-nested-ternary
      this.cols = window.screen.width <= 850 ? 12 : (!this.showFooter ? 12 : 6)

      this.total = this.items.reduce((acumulado, item) => acumulado + item.total, 0)

      for (let i = 0; i < this.items.length; i += 1) {
        const porcentaje = (this.items[i].total / this.total) * 100
        const chartClone = JSON.parse(JSON.stringify(basicChartDonut))
        chartClone.options.colors[0] = this.getColor(this.items[i].total, this.total)
        chartClone.series[0] = porcentaje
        this.chartData.push(chartClone)
      }
      const nombres = []
      const totales = []

      this.items.forEach(item => {
        nombres.push(item.nombre ? item.nombre.toUpperCase() : 'NO REQUIERE CAMBIO')
        totales.push(item.total)
      })

      this.graficaData.data = totales
      this.graficaData.options = chartDonutOptions(nombres, this.total)

      this.graficaData.counter += 1
      this.showGrafica = true
    },
    getColor(value, total) {
      const porcentaje = (value / total) * 100
      if (porcentaje < 10) return this.chartColor[1]
      if (porcentaje < 30) return this.chartColor[4]
      if (porcentaje < 50) return this.chartColor[2]
      if (porcentaje < 75) return this.chartColor[3]
      return this.chartColor[0]
    },
  },
}
</script>

<style scoped>
</style>
