<template>
  <div v-if="showGrafica">
    <b-row>
      <b-col cols="12">
        <div id="grafica_lamparas">
          <bar-chart
            :data="graficaData"
            :height="450"
            :title="title"
            :subtitle="subtitle"
          />
        </div>
      </b-col>
    </b-row>
    <b-row v-if="showFooter">
      <b-col
        v-for="(data, i) in items"
        :key="i"
        cols="6"
        class="mb-2"
      >
        <strong>
          {{ data.nombre ? data.nombre.toUpperCase() : 'NO REQUIERE CAMBIO' }}
        </strong>
        ({{ data.total }} / {{ total }})
        <b-progress
          :max="total"
          :value="data.total"
          height="12px"
          :class="getClass(data.total, total)"
          :variant="getVariant(data.total, total)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import { BRow, BCol, BProgress } from 'bootstrap-vue'
import { barsChartOptions } from '@/utils/charts'

export default {
  components: {
    BarChart, BRow, BCol, BProgress,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      total: 0,
      showGrafica: false,
      graficaData: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
          },
        },
      },
    }
  },
  watch: {
    items: {
      deep: true,
      handler(newVal) {
        this.actualizarGrafica()
      },
    },
  },
  mounted() {
    this.actualizarGrafica()
  },
  methods: {
    actualizarGrafica() {
      this.showGrafica = false
      const nombres = []
      const totales = []

      this.items.forEach(item => {
        nombres.push(item.nombre ? item.nombre.toUpperCase() : 'NO REQUIERE CAMBIO')
        totales.push(item.total)
      })
      this.graficaData = barsChartOptions(nombres, totales, false)
      this.total = this.items.reduce((acumulado, item) => acumulado + item.total, 0)
      this.showGrafica = true
    },
    getClass(value, total) {
      const porcentaje = (value / total) * 100
      if (porcentaje < 10) return 'progress-bar-warning'
      if (porcentaje < 30) return 'progress-bar-success'
      if (porcentaje < 50) return 'progress-bar-secondary'
      if (porcentaje < 75) return 'progress-bar-info'
      return 'progress-bar-primary'
    },
    getVariant(value, total) {
      const porcentaje = (value / total) * 100
      if (porcentaje < 10) return 'warning'
      if (porcentaje < 30) return 'success'
      if (porcentaje < 50) return 'secondary'
      if (porcentaje < 75) return 'info'
      return 'primary'
    },
  },
}
</script>

<style scoped></style>
