<template>
  <div>
    <b-overlay
      :show="loading"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              cols="12"
            >
              <h5 class="text-primary">
                Fotografía de Verificación
              </h5>
              <b-form-group>
                <imagen
                  :key="'foto-verificacion'"
                  :leyenda="'foto-verificacion'"
                  @cargar-file="imagenVerificacion"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <h5 class="text-primary">
                Observaciones
              </h5>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Observaciones"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-no-resize"
                    v-model="observaciones"
                    :state="errors.length > 0 ? false:null"
                    rows="2"
                    no-resize
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col
              style="justify-content: end; display: flex"
              cols="12"
            >
              <b-button
                variant="primary"
                @click="validationForm"
              >
                Guardar
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>
<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormTextarea, BOverlay, BRow,
} from 'bootstrap-vue'
import { required } from '@validations'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { saveImagen } from '@/utils/imagenes'
import { updateCreateMigracion } from '@/utils/migracionLuminarias'
import { mensajeError, mensajeInformativo, notificacionError } from '@/utils/mensajes'

export default {
  components: {
    Imagen,
    BRow,
    BCol,
    BForm,
    BButton,
    BOverlay,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    migracion: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      loading: false,
      modalVerificacion: false,
      observaciones: null,
      fotoVerificacion: '',
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.guardarVerificacion()
          }
        })
    },
    async guardarVerificacion() {
      try {
        this.loading = true
        if (!this.fotoVerificacion || this.fotoVerificacion === '') {
          mensajeError('Debe ingresar una fotografía de la verificación!')
          return
        }
        const usuario = { ...this.usuario }
        const encargado = {
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          email: usuario.email,
        }
        const body = this.migracion
        body.estado = 'FINALIZADO'
        body.tracking.push({
          estado: 'VERIFICACION',
          horaFecha: new Date(),
          usuario: encargado,
        })
        const urlBucket = `migracion-luminarias/${body.id}`
        const isMobile = window.screen.width <= 760
        const imagen = await saveImagen(urlBucket, this.localization, this.fotoVerificacion, 'Foto Verificación', this.observaciones, encargado, isMobile)
        if (imagen) body.seguimiento.push(imagen)
        await updateCreateMigracion(body, 1)
        mensajeInformativo('Guardada', 'Migración Verificada Correctamente')
        this.mandarEmit()
      } catch (error) {
        notificacionError('Algo ha salido mal, intentalo de nuevo! :(')
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    mandarEmit() {
      this.$emit('notificar')
    },
    imagenVerificacion(file) {
      this.fotoVerificacion = file
    },
  },
}

</script>
